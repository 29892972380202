<template>
  <transition name="flixFadeIn">
    <ul class="flix-nav flix-navbar-nav flix-navbar-right" :key="open + $i18n.locale">
      <li class="flix-html-li" v-show="open === true || lang === $i18n.locale" v-for="lang in locales" :key="lang">
        <a href="#" class="flix-html-a" @click.prevent="setLocale(lang)">
          <span :class="{'flix-text-success': lang === $i18n.locale}">{{ lang }}</span>
        </a>
      </li>
    </ul>
  </transition>
</template>
<script>
export default {
  components: {
  },
  props: {},
  data () {
    return {
      open: false,
      locales: Object.keys(this.$i18n.messages)
    }
  },
  methods: {
    setLocale (lang) {
      if (this.open === false) {
        this.open = true
        return false
      }

      const userVariables = this.$getUserVariables()

      this.$flix_post({
        url: 'user/change_language',
        data: {
          user: userVariables.user.md5_id,
          language: lang
        },
        callback: () => {
          this.$i18n.locale = lang
          this.open = false

          if (userVariables.state === 'default') {
            let data = localStorage.getItem('flix_login')
            data = atob(data)
            data = JSON.parse(data)
            data.language = lang
            data = JSON.stringify(data)
            data = btoa(data)
            localStorage.setItem('flix_login', data)
          }
        }
      })
    }
  },
  mounted () {
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  @import '@/assets/style/sass/stylesheet/flix_color_style.scss'
</style>
